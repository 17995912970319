// assets
import {
    IconAffiliate,
    IconBrandChrome,
    IconDashboard,
    IconHelp,
    IconListCheck,
    IconPokeball,
    IconSlice,
    IconSubtask,
    IconUsers
} from '@tabler/icons';

// constant
const icons = { IconSubtask, IconDashboard, IconBrandChrome, IconHelp, IconUsers, IconListCheck, IconSlice, IconPokeball, IconAffiliate };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'ques-management',
            title: 'My Questions',
            type: 'item',
            url: '/my-questions',
            icon: icons.IconListCheck,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
