import useAuth, { ROLE } from 'auth/AuthProvider';
import MainLayout from 'layout/MainLayout';
// project imports
import PropTypes from 'prop-types';
import { lazy } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

const SamplePage = Loadable(lazy(() => import('views/sample-page')));

const Home = Loadable(lazy(() => import('views/home/Home')));

// admin
const UsersManagement = Loadable(lazy(() => import('views/users-management')));
const EditorsManagement = Loadable(lazy(() => import('views/editor-mamagement')));
const ApproverManagement = Loadable(lazy(() => import('views/approver-management')));
const QuizManagement = Loadable(lazy(() => import('views/quiz-management')));
const CategoriesManagement = Loadable(lazy(() => import('views/categories-management')));
const QuestionsManagement = Loadable(lazy(() => import('views/ques-management')));
const DailyQuiz = Loadable(lazy(() => import('views/daily-quizes')));
const UpdateEditorQuestionPage = Loadable(lazy(() => import('views/pages/add-forms/UpdateEditorQuestion')));
const AddCategoryPage = Loadable(lazy(() => import('views/pages/add-forms/AddCategory')));
const UpdateUserPage = Loadable(lazy(() => import('views/pages/add-forms/UpdateUser')));
const AddUpdateQuiz = Loadable(lazy(() => import('views/pages/add-forms/AddQuiz')));
const AddDailyQuiz = Loadable(lazy(() => import('views/pages/add-forms/AddDailyQuiz')));
// const UpdateQuestionPage = Loadable(lazy(() => import('views/pages/add-forms/UpdateQuestion')));

// editor
const EditorQuestionsManagement = Loadable(lazy(() => import('views/EditorPages/EditorQuestionsManagement')));
const AddQuestionPage = Loadable(lazy(() => import('views/pages/add-forms/AddQuestion')));

// approver
const EditorQuestions = Loadable(lazy(() => import('views/editor-questions')));

// ==============================|| MAIN ROUTING ||============================== //

const { ADMIN, EDITOR, APPROVER } = ROLE;

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: (
                <RequireAuth>
                    <Home />
                </RequireAuth>
            )
        },
        {
            path: '/dashboard',
            element: (
                <RequireAuth role={ADMIN}>
                    <EditorQuestions />
                </RequireAuth>
            )
        },
        {
            path: '/users-management',
            element: (
                <RequireAuth role={ADMIN}>
                    <UsersManagement />
                </RequireAuth>
            )
        },
        {
            path: '/quiz-management',
            element: (
                <RequireAuth role={ADMIN}>
                    <QuizManagement />
                </RequireAuth>
            )
        },
        {
            path: '/quiz-management/update/:id',
            element: (
                <RequireAuth role={ADMIN}>
                    <AddUpdateQuiz />
                </RequireAuth>
            )
        },
        {
            path: '/users-management/update/:id',
            element: (
                <RequireAuth role={ADMIN}>
                    <UpdateUserPage />
                </RequireAuth>
            )
        },
        {
            path: '/categories-management',
            element: (
                <RequireAuth role={ADMIN}>
                    <CategoriesManagement />
                </RequireAuth>
            )
        },

        {
            path: '/add-category',
            element: (
                <RequireAuth role={ADMIN}>
                    <AddCategoryPage />
                </RequireAuth>
            )
        },
        {
            path: '/add-quiz',
            element: (
                <RequireAuth role={ADMIN}>
                    <AddUpdateQuiz />
                </RequireAuth>
            )
        },
        {
            path: '/add-daily-quiz',
            element: (
                <RequireAuth role={ADMIN}>
                    <AddDailyQuiz />
                </RequireAuth>
            )
        },
        {
            path: '/daily-quiz/update/:id',
            element: (
                <RequireAuth role={ADMIN}>
                    <AddDailyQuiz />
                </RequireAuth>
            )
        },
        {
            path: '/editor-management',
            element: (
                <RequireAuth role={ADMIN}>
                    <EditorsManagement />
                </RequireAuth>
            )
        },
        {
            path: '/approver-management',
            element: (
                <RequireAuth role={ADMIN}>
                    <ApproverManagement />
                </RequireAuth>
            )
        },
        {
            path: '/ques-management',
            element: (
                <RequireAuth role={ADMIN}>
                    <QuestionsManagement />
                </RequireAuth>
            )
        },
        {
            path: '/ques-management/update/:id',
            element: (
                <RequireAuth role={ADMIN}>
                    <AddQuestionPage />
                </RequireAuth>
            )
        },
        {
            path: '/daily-quizes',
            element: (
                <RequireAuth role={ADMIN}>
                    <DailyQuiz />
                </RequireAuth>
            )
        },
        {
            path: '/daily-quizes/update/:id',
            element: (
                <RequireAuth role={ADMIN}>
                    <AddDailyQuiz />
                </RequireAuth>
            )
        },
        {
            path: '/editor-questions',
            element: (
                <RequireAuth role={APPROVER}>
                    <EditorQuestions />
                </RequireAuth>
            )
        },
        {
            path: '/editor-questions/update/:id',
            element: (
                <RequireAuth role={APPROVER}>
                    <UpdateEditorQuestionPage />
                </RequireAuth>
            )
        },
        {
            path: '/my-questions',
            element: (
                <RequireAuth role={EDITOR}>
                    <EditorQuestionsManagement />
                </RequireAuth>
            )
        },
        {
            path: '/my-questions/update/:id',
            element: (
                <RequireAuth role={EDITOR}>
                    <AddQuestionPage />
                </RequireAuth>
            )
        },
        {
            path: '/add-question',
            element: (
                <RequireAuth role={EDITOR}>
                    <AddQuestionPage />
                </RequireAuth>
            )
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        }
    ]
};

function RequireAuth({ role, children }) {
    const auth = useAuth();
    const location = useLocation();

    if (auth.initializing) return null;

    if (!auth.user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (auth.user.role === ROLE.ADMIN) {
        return children;
    }

    if (role && auth.user.role !== role) {
        return <Navigate replace to="/" />;
    }

    return children;
}

RequireAuth.propTypes = {
    children: PropTypes.node,
    role: PropTypes.string
};

export default MainRoutes;
