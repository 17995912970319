import App from 'App';
// style + assets
import 'assets/scss/style.scss';
import { AuthProvider } from 'auth/AuthProvider';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// third party
import { HashRouter } from 'react-router-dom';
// project imports
import * as serviceWorker from 'serviceWorker';
import { store } from 'store';

// ==============================|| REACT DOM RENDER ||============================== //

async function main() {
    ReactDOM.render(
        <AuthProvider>
            <Provider store={store}>
                <HashRouter>
                    <App />
                </HashRouter>
            </Provider>
        </AuthProvider>,
        document.getElementById('root')
    );
}
main();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
