import client from 'api/processRequest';

export default {
    auth: {
        async sendOtp(phone, isAdmin = false) {
            if (isAdmin) {
                return client.post('/login/phone', {
                    phone: `${phone}`
                });
            }
            return client.post('/login/phone/editor', {
                phone: `${phone}`
            });
        },
        async verifyOtp(userId, otp) {
            return client.get(`/user/${userId}/verify-otp?otp=${otp}`);
        }
    },
    common: {
        async uploadImage(formData) {
            return client.post('/common/image', formData);
        }
    },
    admin: {
        async getAllUsers(page, { phone, role } = {}) {
            let endpoint = `/admin/user?pagination=${page}`;

            if (phone) {
                endpoint += `&phone=${phone}`;
            }

            if (role) {
                endpoint += `&role=${role}`;
            }

            return client.get(endpoint);
        },
        async updateUser(userId, data) {
            return client.patch(`/admin/user/${userId}`, data);
        },
        async updateRoleForUser(phone, role) {
            return client.patch(`/admin/updateRole?phone=${phone}&role=${role}`);
        },
        async getQuestions(pagination, match = '', id = '') {
            let ep = `/admin/ques?pagination=${pagination}`;
            if (id) {
                ep += `&id=${id}`;
            }
            if (match) {
                ep += `&match=${match}`;
            }

            return client.get(ep);
        },
        async updateQuestion(id, question) {
            return client.patch(`/admin/ques?id=${id}`, question);
        },
        async addQuestion(question) {
            return client.post(`/admin/ques`, question);
        },
        async getAllCategories() {
            return client.get(`/admin/category`);
        },
        async addCategory(category) {
            return client.put(`/admin/category`, category);
        },
        async getSinglePlayerQuizzes(categoryId) {
            return client.get(`/admin/singlePlayerQuiz?categoryId=${categoryId}`);
        },
        async updateSinglePlayerQuiz(quizId, data) {
            return client.patch(`/admin/singlePlayerQuiz?id=${quizId}`, data);
        },
        async addSinglePlayerQuiz(data) {
            return client.post(`/admin/singlePlayerQuiz`, data);
        },
        // Daily Quizes
        async createDailyQuiz(data) {
            return client.post('/dailyQuiz/create', data);
        },
        async getDailyQuizes(time = 'present') {
            return client.get(`/dailyQuiz/fetch?time=${time}`);
        },
        async updateDailyQuiz(data, id) {
            return client.post(`/dailyQuiz/update?quizId=${id}`, data);
        },
        async deleteDailyQuiz(id) {
            return client.delete(`/dailyQuiz/delete?quizId=${id}`);
        },
        //
        async getEditorQuestions(pageNo, approvalStatus = '', key = '', editorId = '', date = '') {
            let ep = `/admin/editorQues?pageNo=${pageNo}`;
            if (approvalStatus) {
                ep += `&approvalStatus=${approvalStatus}`;
            }
            if (key) {
                ep += `&key=${key}`;
            }
            if (editorId) {
                ep += `&editorId=${editorId}`;
            }
            if (date) {
                ep += `&date=${date}`;
            }
            return client.get(ep);
        },
        async updateEditorQuestion(id, question) {
            return client.patch(`/admin/editorQues?editorQuestionId=${id}`, question);
        },
        async getEditorStats(pageNo) {
            return client.get(`/admin/editorStats?pageNo=${pageNo}`);
        },
        async getApproverStats(pageNo) {
            return client.get(`/admin/approverStats?pageNo=${pageNo}`);
        }
    },
    approver: {
        async getEditorQuestions(pageNo, approvalStatus, key = '', editorId = '', date = '') {
            let ep = `/approver/editorQues?pageNo=${pageNo}`;
            if (approvalStatus) ep += `&approvalStatus=${approvalStatus}`;
            if (key) ep += `&key=${key}`;
            if (editorId) ep += `&editorId=${editorId}`;
            if (date) ep += `&date=${date}`;
            return client.get(ep);
        },
        async updateEditorQuestionStatus(id, status) {
            return client.get(`/approver/approveEditorQues?editorQuestionId=${id}&approvalStatus=${status}`);
        },
        async updateEditorQuestion(id, question) {
            return client.patch(`/approver/editorQues?editorQuestionId=${id}`, question);
        },
        async getQuestionById(id) {
            return client.get(`/approver/fetchQuestions?questionId=${id}`);
        }
    },
    editor: {
        async addQuestion(question) {
            return client.post(`/editor/ques`, question);
        },
        async getEditorQuestions(pageNo, key = '', approvalStatus = 'pending') {
            let ep = `/editor/editorQues?pageNo=${pageNo}`;
            if (approvalStatus) {
                ep += `&approvalStatus=${approvalStatus}`;
            }
            if (key) {
                ep += `&key=${key}`;
            }
            return client.get(ep);
        },
        async getEditorQuestionById(id) {
            return client.get(`/editor/editorQues?pageNo=1&questionId=${id}`);
        },
        async updateQuestion(id, question) {
            return client.patch(`/editor/ques?id=${id}`, question);
        }
    }
};
