import admin from 'menu-items/admin';
import editor from 'menu-items/editor';
import approver from 'menu-items/approver';

// ==============================|| MENU ITEMS ||============================== //

export default {
    admin,
    editor,
    approver,
    user: editor
};
