/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import api from 'api';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext({
    initializing: true,
    otp: null,
    user: null,
    sendOtp: async (phone, role) => {},
    verifyOtp: async (otp, onSuccess, onError) => {},
    logout: () => {}
});

export const ROLE = {
    ADMIN: 'admin',
    USER: 'user',
    EDITOR: 'editor',
    APPROVER: 'approver'
};

export function AuthProvider({ children }) {
    const [initializing, setInitializing] = useState(true);
    const [user, setUser] = useState(null);
    const [otp, setOtp] = useState(null);

    useEffect(() => {
        async function init() {
            const token = localStorage.getItem('token');

            if (token) {
                setUser(JSON.parse(localStorage.getItem('user')));
            }

            setInitializing(false);
        }

        init();
    }, []);

    const sendOtp = async (phone, role) => {
        try {
            let res;
            if (role === ROLE.ADMIN) {
                res = await api.ikc.auth.sendOtp(phone, true);
            } else {
                res = await api.ikc.auth.sendOtp(phone, false);
            }
            setOtp(res.payload);
        } catch (error) {
            alert(error);
        }
    };

    const verifyOtp = async (_otp, onSuccess, onError) => {
        try {
            const response = await api.ikc.auth.verifyOtp(otp._id, _otp);
            setUser(response.payload.user);
            localStorage.setItem('user', JSON.stringify(response.payload.user));

            localStorage.setItem('token', response.payload.token.token);
            onSuccess();
        } catch (error) {
            onError(error);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setUser(null);
        setOtp(null);
    };

    return <AuthContext.Provider value={{ otp, user, initializing, sendOtp, verifyOtp, logout }}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
    children: PropTypes.node
};

export default function useAuth() {
    return useContext(AuthContext);
}
