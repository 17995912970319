// assets
import { IconKey, IconPlus } from '@tabler/icons';

// constant
const icons = {
    IconKey,
    IconPlus
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'Pages',
    caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-forms',
            title: 'Add',
            type: 'collapse',
            icon: icons.IconPlus,

            children: [
                {
                    id: 'add-question',
                    title: 'Question',
                    type: 'item',
                    url: '/add-question',
                    target: true
                },
                {
                    id: 'add-category',
                    title: 'Category',
                    type: 'item',
                    url: '/add-category',
                    target: true
                },
                {
                    id: 'add-quiz',
                    title: 'Quiz',
                    type: 'item',
                    url: '/add-quiz',
                    target: true
                },
                {
                    id: 'add-daily-quiz',
                    title: 'Daily Quiz',
                    type: 'item',
                    url: '/add-daily-quiz',
                    target: true
                }
            ]
        }
        // {
        //     id: 'authentication',
        //     title: 'Authentication',
        //     type: 'collapse',
        //     icon: icons.IconKey,

        //     children: [
        //         {
        //             id: 'login3',
        //             title: 'Login',
        //             type: 'item',
        //             url: '/pages/login/login3',
        //             target: true
        //         },
        //         {
        //             id: 'register3',
        //             title: 'Register',
        //             type: 'item',
        //             url: '/pages/register/register3',
        //             target: true
        //         }
        //     ]
        // }
    ]
};

export default pages;
