/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'api';

const initialState = {
    categories: [],
    selectedCategoryId: '',
    status: 'idle',
    error: null
};

const fetchCategories = createAsyncThunk('users/fetchCategories', async () => {
    const response = await api.ikc.admin.getAllCategories();
    return response.payload;
});

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setSelectedCatedgoryId(state, action) {
            const id = action.payload;
            const existingCategory = state.categories.find((category) => category._id === id);
            if (existingCategory) {
                state.selectedCategoryId = id;
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCategories.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Add any fetched posts to the array
                state.categories = action.payload;
                state.selectedCategoryId = state.categories[0]._id;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

const selectAllCategories = (state) => state.categories.categories;

export { selectAllCategories, fetchCategories };
export const { setSelectedCatedgoryId } = categoriesSlice.actions;
export default categoriesSlice.reducer;
