/* eslint-disable func-names */

import config from 'config';

/**
 * Global network request handler
 * @param {string} url url to fetch
 * @param {Request} options fetch options
 * @returns {Promise<any>}
 */
export default async function client(endpoint, options = {}) {
    const ts = Date.now();
    const method = options?.method || 'GET';
    const headers = { 'Content-Type': 'application/json' };

    if (localStorage.getItem('token')) {
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }

    const requestOptions = {
        method: options?.method || 'GET',
        ...options,
        headers: {
            ...options?.headers,
            ...headers
        }
    };

    if (options?.body) {
        requestOptions.body = JSON.stringify(options.body);
    }

    let data;
    try {
        const response = await window.fetch(`${config.baseUrl}${endpoint}`, requestOptions);
        data = await response.json();

        if (response.ok) {
            console.groupCollapsed(`%c ${method}${response.status}: ${Date.now() - ts}ms ${endpoint}`, 'background: #12bc00; color: #000');
            return data;
        }

        console.groupCollapsed(`%c ${method}${response.status}: ${Date.now() - ts}ms ${endpoint}`, 'background: #ff0039; color: #000');
        throw new Error(data.message);
    } catch (err) {
        return Promise.reject(err.message ? err.message : data);
    } finally {
        console.groupEnd();
    }
}

client.get = function (endpoint, customConfig = {}) {
    return client(endpoint, { ...customConfig, method: 'GET' });
};

client.post = function (endpoint, body, customConfig = {}) {
    return client(endpoint, { ...customConfig, body, method: 'POST' });
};

client.patch = function (endpoint, body, customConfig = {}) {
    return client(endpoint, { ...customConfig, body, method: 'PATCH' });
};
client.put = function (endpoint, body, customConfig = {}) {
    return client(endpoint, { ...customConfig, body, method: 'PUT' });
};
client.delete = function (endpoint, body, customConfig = {}) {
    return client(endpoint, { ...customConfig, body, method: 'DELETE' });
};
