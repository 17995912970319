// assets
import {
    IconAffiliate,
    IconBrandChrome,
    IconDashboard,
    IconHelp,
    IconListCheck,
    IconPokeball,
    IconSlice,
    IconSubtask,
    IconUsers
} from '@tabler/icons';

// constant
const icons = { IconSubtask, IconDashboard, IconBrandChrome, IconHelp, IconUsers, IconListCheck, IconSlice, IconPokeball, IconAffiliate };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'users-management',
            title: 'Users Management',
            type: 'item',
            url: '/users-management',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'categories-management',
            title: 'Categories Management',
            type: 'item',
            url: '/categories-management',
            icon: icons.IconSlice,
            breadcrumbs: false
        },
        {
            id: 'quiz-management',
            title: 'Quiz Management',
            type: 'item',
            url: '/quiz-management',
            icon: icons.IconSubtask,
            breadcrumbs: false
        },
        {
            id: 'approver-management',
            title: 'Approver Management',
            type: 'item',
            url: '/approver-management',
            icon: icons.IconPokeball,
            breadcrumbs: false
        },
        {
            id: 'ques-management',
            title: 'Questions Management',
            type: 'item',
            url: '/ques-management',
            icon: icons.IconListCheck,
            breadcrumbs: false
        },
        {
            id: 'editor-management',
            title: 'Editors Management',
            type: 'item',
            url: '/editor-management',
            icon: icons.IconPokeball,
            breadcrumbs: false
        },
        {
            id: 'editor-questions',
            title: 'Editor Questions',
            type: 'item',
            url: '/editor-questions',
            icon: icons.IconAffiliate,
            breadcrumbs: false
        },
        {
            id: 'daily-quizes',
            title: 'Daily Quizes',
            type: 'item',
            url: '/daily-quizes',
            icon: icons.IconSlice,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
