// reducer import
import customizationReducer from './customizationReducer';
import categoriesReducer from './slices/categorySlice';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = {
    customization: customizationReducer,
    categories: categoriesReducer
};

export default reducer;
