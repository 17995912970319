import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import useAuth from 'auth/AuthProvider';
import { selectAllCategories, fetchCategories } from 'store/slices/categorySlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user } = useAuth();
    const categories = useSelector(selectAllCategories);

    const dispatch = useDispatch();

    useEffect(() => {
        if (categories.length === 0 && user) {
            dispatch(fetchCategories());
        }
    }, [categories, user, dispatch]);
    return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
